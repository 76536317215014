import * as UI from '@openstax/ui-components';
import { errorPath } from '@project/lambdas/build/src/services/LtiJs/paths.config';
import { createRoute, makeScreen } from '../../../core/services';
import { useQuery } from '../../../routing/useQuery';
import { assertString } from '@openstax/ts-utils/assertions';
import { Screen } from '../styled';

const ErrorScreenWithNavBar = (
  props: React.PropsWithChildren<{ heading: string }>,
) => (
  <>
    <UI.NavBar logo={true} />
    <Screen>
      <h1>{props.heading}</h1>
      <p>{props.children}</p>
    </Screen>
  </>
);

const errorScreens = {
  MISSING_VALIDATION_COOKIE: (
    <UI.Error>
      <p>
        Please enable third-party cookies in your web browser settings. Cookies
        allow us to ensure you remain logged in, and are required by the LTI
        specification for security purposes.
      </p>

      <p><a href="https://help.openstax.org/s/article/How-do-I-enable-third-party-cookies"
         target="_blank"
         rel="noreferrer">Learn how to enable third-party cookies here.</a></p>
    </UI.Error>
  ),
  DEPLOYMENT_REGISTRATION_ERROR: (
    <ErrorScreenWithNavBar heading="We couldn't complete your request.">
      It looks like your LT integration is not complete. Please contact your
      LMS admin to finish the registration process.
    </ErrorScreenWithNavBar>
  ),
  MISSING_CONTEXT: (
    <UI.Error>
      OpenStax Assignable requires course information that your learning
      management system has not provided. Please ask your LMS admin to
      enable this setting. See the <a
      href="https://help.openstax.org/s/topic/0TO6f000000oeevGAA/assignable"
      target="_blank"
      rel="noreferrer">Support Center</a> for more information.
    </UI.Error>
  ),
  GENERIC: (
    <UI.Error>
      We're not quite sure what went wrong. Restarting your browser or making
      sure you have cookies enabled may solve the problem. For more help visit
      our <a href="https://openstax.secure.force.com/help" target="_blank" rel="noreferrer">
      Support Center</a>.
    </UI.Error>
  ),
};

export const errorScreen = createRoute({
  name: "UI.Error Screen",
  path: errorPath,
  handler: makeScreen(() => {
    const error = assertString(
      useQuery().error,
      new Error("error must be a string"),
    );
    return errorScreens[(error as keyof typeof errorScreens) || "GENERIC"];
  }),
});
