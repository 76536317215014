import {
  FetchState, fetchLoading, stateHasError, fetchError, fetchSuccess, stateHasData
} from '@openstax/ts-utils/fetch';
import debounce from 'debounce-promise';
import React from 'react';
import { StateContext } from '.';
import { useApiClient } from '../../../api';
import { AsyncSelectField } from './fields';

export const SearchOrganizations = () => {
  const apiClient = useApiClient();
  const [
    searchResults,
    setSearchResults
  ] = React.useState<FetchState<{ value: string; label: string }[], string>>(fetchLoading());
  const { state, dispatch } = React.useContext(StateContext);

  const searchOrganizations = debounce(async (inputValue: string) => {
    return apiClient.apiV0SearchOrganizations({ query: { query: inputValue } })
      .then(response => {
        if (response.status === 429) {
          setSearchResults(previous => fetchError('Rate limit exceeded. Please try again later.', previous));
          return [];
        } else {
          return response.acceptStatus(200).then((response) => response.load()).then((response) => {
            const results = response.organizations.map(o => (
              { value: o.id, label: o.name }
            ));
            setSearchResults(fetchSuccess(results));
            return results;
          });
        }
      })
      .catch(() => {
        setSearchResults(previous => fetchError('An error occurred. Please try again.', previous));
        return [];
      });
  }, 500);

  const loadOptions = (inputValue: string) => (
    inputValue.length > 2 ? searchOrganizations(inputValue) : Promise.resolve([])
  );

  return <AsyncSelectField
    label='Organization'
    defaultValue={
      state.contactPayload.organizationId &&
      state.contactPayload.organizationName ? {
        label: state.contactPayload.organizationName,
        value: state.contactPayload.organizationId,
      } : null}
    defaultOptions={stateHasData(searchResults) ? searchResults.data : []}
    loadOptions={loadOptions}
    noResultsMessage={'No organizations found.'}
    placeholder={'Search for your organization...'}
    searchErrorMessage={stateHasError(searchResults) ? searchResults.error : undefined}
    onChange={(org) => dispatch(
      {
        type: 'setContactOrganization',
        payload: org ? { organizationId: org.value, organizationName: org.label } :
                       { organizationId: '', organizationName: '' }
      }
    )}
  />;
};
